<template>
  <div class="news-container">
    <div class="banner" :style ="banner">
      <div class="min-size">
        <div class="router-title">新闻动态</div>
        <div class="router-title-en">NEWS INFORMATION</div>
      </div>
    </div>

    <div class="content">
      <div class="min-size">
        <div class="list-tab">
          <div class="tab-item" v-for="(item,index) in tab" :key="index" :class="{ 'active': currentTab === index+1 }" @click="chooseTab(index+1)">{{item.name}}</div>
        </div>
        <div class="news-list">
          <div v-show="currentTab === 1 || currentTab === 2" class="item-list-img">
              <router-link v-for="(item, index) in list" :key="index" :to="'/news/view/'+item.id">
                <div class="item">
                  <div class="left">
                    <img :src="item.thumbnail" rel="noreferrer" referrer="no-referrer" alt="news-item-image">
                  </div>
                  <div class="right">
                    <div class="title">{{ item.title }}</div>
                    <!-- <div class="summary">{{item.summary}}</div> -->
                    <div class="create-at">{{ item.pushTime }}</div>
                  </div>
                </div>
              </router-link>
          </div>

          <div v-show="currentTab === 3" class="item-list-text">
            <router-link v-for="(item, index) in list" :key="index" class="item" :to="'/news/view/'+item.id">
             <div class="item">
                <div class="right">
                    <div class="title" v-html="item.title"></div>
                    <div class="summary">{{item.summary}}</div>
                    <div class="create-at">{{ item.pushTime }}</div>
                </div>
             </div>
            </router-link>
          </div>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10]"
            :page-size="10"
            layout="prev, pager, next, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNewsList,archivesList,archivesDetail } from '@/api/api'
const defaultSettings = require('@/settings.js')
export default {
  name: 'News',
  components: {
  },
  created () {
    this.getList()
  },
  data () {
    return {
      banner: {
        backgroundImage: 'url(' + require('@/assets/yf/news-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      companyid:'',
      mediaid:'',
      guanwang:'',
      currentTab: 1,
      currentPage: 1,
      total: 0,
      tab:[],
      list: [],
      list1: [
       
      ],
      list2: [
       
      ],
      list3: [
       
      ]
    }
  },
  methods: {
    chooseTab (index) {
      let id = this.tab[index-1].id
      archivesList({currentPage:1,currentSize:99,id:id}).then(response => {
        let arr = []
        arr = response.data.rows

        this.list = arr
        this.total = this.list.length
      })
      // if (index === 1) {
      //   this.list = this.list1
      // }

      // if (index === 2) {
      //   this.list = this.list2
      // }

      // if (index === 3) {
      //   this.list = this.list3
      // }

      this.currentTab = index
      
    },

    getList() {
      fetchNewsList().then(response => {
        response.data.map((r)=>{
          if(r.name == '湖南银方'){
              this.tab = r.children
              this.chooseTab(this.currentTab)
          
          }
        })
      })
    },

    handleSizeChange () {
    },
    handleCurrentChange () {
    }
  }
}
</script>

<style lang="less">
.news-container {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 470px;

    .min-size {
      width: 1200px;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .router-title {
        font-size: 48px;
        color: #ffffff;
      }

      .router-title-en {
        font-size: 48px;
        color: rgba(255,255,255,0.3);
        font-weight: 300;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .min-size {
      width: 1200px;

      .list-tab {
        display: flex;
        justify-content: center;
        margin-top: 75px;
        height: 50px;

        .tab-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 100%;
          cursor: pointer;
          font-size: 18px;
          color: #000000;

          &:nth-child(2) {
            margin: 0 50px;
          }

          &.active {
            background: #c00000;
            color: #ffffff;
            font-weight: 700;
          }
        }
      }

      .news-list {
        width: 100%;
        padding: 70px 0 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-list-img {
          width: 100%;

          a {
            text-decoration: none;

            .item {
              width: 100%;
              border-bottom: 1px solid #f0f0f0;
              display: flex;
              align-items: center;

              &:hover {
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);

                .left {
                  img {
                    transform: scale(1.1);
                  }
                }

                .right {
                  .title {
                    color: #c00000;
                     p{
                      margin: 0px !important;
                    }
                  }
                }
              }

              .left {
                width: 310px;
                height: 230px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  transition: all 0.3s;
                }
              }

              .right {
                padding-left: 40px;
                flex-grow: 1;

                .title {
                  display: inline-block;
                  width: 440px;
                  font-size: 24px;
                  color: #333333;
                  line-height: 40px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  p{
                    margin: 0px;
                  }
                }

                .summary{
                  display: inline-block;
                  width: 340px;
                  font-size: 14px;
                  color: #999999;
                  line-height: 40px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                .create-at {
                  float:right;
                  display: inline-block;
                  padding-right: 60px;
                  font-size: 14px;
                  color: #999999;
                  line-height: 40px;
                }
              }
            }
          }
        }

        .item-list-text {
         width: 100%;

          a {
            text-decoration: none;

            .item {
              width: 100%;
              border-bottom: 1px solid #f0f0f0;
              display: flex;
              align-items: center;

              &:hover {
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);

                .left {
                  img {
                    transform: scale(1.1);
                  }
                }

                .right {
                  .title {
                    color: #c00000;
                    p{
                      margin: 0px !important;
                    }
                  }
                }
              }

              .left {
                width: 310px;
                height: 230px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  transition: all 0.3s;
                }
              }

              .right {
                padding-left: 40px;
                flex-grow: 1;

                .title {
                  display: inline-block;
                  width: 440px;
                  font-size: 24px;
                  color: #333333;
                  line-height: 40px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                   p{
                      margin: 0px !important;
                    }
                }

                .summary{
                  display: inline-block;
                  width: 340px;
                  font-size: 14px;
                  color: #999999;
                  line-height: 40px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                .create-at {
                  float:right;
                  display: inline-block;
                  padding-right: 60px;
                  font-size: 14px;
                  color: #999999;
                  line-height: 40px;
                }
              }
            }
          }
        }

        .el-pagination {
          padding-top: 70px;
        }
      }
    }
  }
}
</style>
